
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { WidgetEnum } from '@/models/enums/WidgetEnum';
import { isEmpty } from '@/utils/misc';
import { abbreviateNumber, abbreviateNumberWithCurrency } from '@/utils/number';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  methods: {
    isArray: Array.isArray,
  },
})
export default class FiguresWidget extends Vue {
  @Prop({ required: true })
  private data!: any;

  @Prop({ required: true, default: false })
  private loading!: boolean;

  @Prop({ required: true })
  private widgetDefinition!: WidgetDefinition;

  private get parsedData(): any {
    if (this.data === null) {
      return {};
    }

    if (this.widgetUsesCustomerNo) {
      return this.updateFiguresData(this.filterFigures(this.data));
    }

    return this.updateFiguresData(this.data);
  }

  private filterFigures(data: any): any {
    const figuresToShow = [
      'nQuotes',
      'nAccepted',
      'nRejected',
      'avgConversionTime',
      'avgAmount',
      'avgConvertedAmount',
    ];
    return Object.keys(data)
      .filter((key) => figuresToShow.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: data[key],
        };
      }, {});
  }

  private updateFiguresData(data: any) {
    return Object.fromEntries(
      Object.keys(data).map((key: string, index: number) => {
        const value = data[key];
        if (isNaN(value) && this.isList(value)) {
          const hasCurrencyByIndex: any = this.hasCurrency(index);

          return [
            key,
            value
              .split('/')
              .map((valueNumber: number, i: number) =>
                hasCurrencyByIndex[i]
                  ? abbreviateNumberWithCurrency(valueNumber)
                  : abbreviateNumber(valueNumber),
              ),
          ];
        }
        return this.hasCurrency(index)
          ? [key, abbreviateNumberWithCurrency(value)]
          : [key, abbreviateNumber(value)];
      }),
    );
  }

  // true - the figure has currency
  // false - the figure does not have currency
  private hasCurrency(index: number): boolean | boolean[] {
    switch (this.widget) {
      case WidgetEnum.BusinessOverviewFigures:
        const businessOverviewCurrency: Array<boolean | boolean[]> = [
          [false, true],
          [false, true],
          [false, true],
          true,
          true,
          true,
          false,
          false,
          [false, true],
        ];
        return businessOverviewCurrency[index];
      case WidgetEnum.QuotesFigures:
        const quotesCurrency: boolean[] = [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          true,
          true,
        ];
        return quotesCurrency[index];
      case WidgetEnum.SalesFigures:
        const salesCurrency: boolean[] = [false, true, true, false, false, false, true, true, true];
        return salesCurrency[index];
      case WidgetEnum.QuotesFiguresDetailed:
        const quotesDetailedCurrency: boolean[] = [false, false, false, false, true, true];
        return quotesDetailedCurrency[index];
      case WidgetEnum.TechnologyOverviewSscFigures:
        return false;
      default:
        return false;
    }
  }

  private consoleString(key: string) {
    switch (this.widget) {
      case WidgetEnum.ManufacturingFigures:
        return this.$t(`report.manufacturing_console.${key}`);
      case WidgetEnum.TechnologyOverviewSscFigures:
        return this.$t(`report.technology_console_ssc_figures.${key}`);
      default:
        return this.$t(`report.business_console.${key}`);
    }
  }

  private get resultKeys(): string[] {
    return Object.keys(this.parsedData);
  }

  private isList(value: string): boolean {
    return /^(\d+(.\d+)?\/)+\d+(.\d+)?$/.test(value);
  }

  private get widget(): WidgetEnum {
    return this.widgetDefinition.widget;
  }

  private get widgetUsesCustomerNo(): boolean {
    return this.widget === WidgetEnum.QuotesFiguresDetailed;
  }

  private get hasData(): boolean {
    return !isEmpty(this.parsedData);
  }
}
